const Papa = require('papaparse');

export const formatDataForTable = (data) => {
  let formattedData = [];
  data.forEach((d) => {
    if (d !== '') formattedData.push({ id: d, name: d });
  });

  return formattedData;
};

export const formatPathName = (pathname) => {
  const formattedPathname = capitalizeEveryWord(pathname.replace(/-/g, ' '));
  return formattedPathname;
};

export const capitalizeEveryWord = (text) => {
  let splitText = text.split(' ');

  for (let i = 0; i < splitText.length; i++) {
    splitText[i] = splitText[i][0].toUpperCase() + splitText[i].substr(1);
  }

  const capitalizedText = splitText.join(' ');
  return capitalizedText;
};

export const convertJSONToCSV = (jsonObject) => {
  const csv = Papa.unparse(jsonObject);
  return csv;
};

export const addMetadata = (jsonObject, username, method) => {
  console.debug(username);
  const newRows = [...jsonObject];
  newRows.forEach((row) => {
    row['user'] = username;
    row['method'] = method ? method : 'NA';
  });
  return newRows;
};

export const formatInputData = (inputData) => {
  const formattedData = [];
  inputData.forEach((row) => {
    formattedData.push({
      id: row.id,
      ngs_run_name: row.ngs_run_name,
      analysis_name: row.analysis_name,
      plate_name: row.plate_name,
      well: row.well,
      library_number: row.library_number,
      library_prep_kit: row.library_prep_kit,
      library_prep_plate: row.library_prep_plate,
      library_well: row.library_well,
    });
  });
  return formattedData;
};
