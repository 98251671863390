import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Link as MuiLink, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MaterialReactTable from 'material-react-table';
import { useParams } from 'react-router-dom';
import {
  editComment,
  getMiSeqRunsById,
} from '../../../services/MiSeqRunsService';
import { downloadFile } from '../../../services/SamplesService';
import Breadcrumbs from '../../common/Breadcrumbs';
import MenuDropdown from '../../common/MenuDropdown';
import UnauthenticatedPage from '../../common/UnauthenticatedPage.jsx';
import PageTitle from '../../layout/PageTitle';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const MiSeqRunAnalysis = () => {
  let { id } = useParams();
  const [samples, setSamples] = useState([]);
  const [loading, setLoading] = useState();
  const [optionsMultiQC, setOptionsMultiQC] = useState();
  const [optionsHTML, setOptionsHTML] = useState();
  const [optionsSampleSheet, setOptionsSampleSheet] = useState();
  const [optionsWebAppInput, setOptionsWebAppInput] = useState();
  const [description, setDescription] = useState('No description provided');
  const [previousDescription, setPreviousDescription] = useState();
  const [date, setDate] = useState();
  const [analysisName, setAnalysisName] = useState();
  const [runId, setRunId] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openQuitDialog, setOpenQuitDialog] = useState(false);
  const [isWebAppInput, setIsWebAppInput] = useState();

  const isSameDay = (date, filterValue) => {
    let filterDate = new Date(new Date(filterValue).toDateString());
    let dateToFilter = new Date(date.toDateString());
    if (filterValue === null) return true; // To make the "clear" filter work: the "clear" makes the filterValue null.
    if (dateToFilter < filterDate) return false;
    else if (dateToFilter > filterDate) return false;
    else return true;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sample.sample_name',
        header: 'Sample',
      },
      {
        accessorKey: 'sample.reference',
        header: 'Reference',
      },
      {
        accessorKey: 'sample.identifier',
        header: 'Identifier',
        Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      },
      {
        accessorKey: 'sample.library_date',
        header: 'Date',
        enableGrouping: false,
        enableHiding: false,
        accessorFn: (row) => new Date(row.sample.library_date), //convert to Date for sorting and filtering
        filterFn: (row, id, filterValue) =>
          isSameDay(row.getValue(id), filterValue),
        Cell: ({ cell }) => (
          <Typography>
            {new Date(cell.getValue()).toLocaleDateString('fr-FR')}
          </Typography>
        ),
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              clearable
              disableFuture
              slotProps={{
                textField: {
                  helperText: 'Filter mode: Equal to',
                  variant: 'standard',
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: 'error_rates_file_url',
        header: 'Error rates',
        sortable: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box display='flex' flexDirection='row' alignItems='baseline'>
              <MuiLink
                aria-label='open_in_new'
                href={cell.getValue()}
                target='_blank'>
                <OpenInNewIcon color='secondary' />
              </MuiLink>
              <Button
                color='secondary'
                aria-label='download'
                onClick={() => downloadFile(cell.getValue())}>
                <FileDownloadIcon color='secondary' />
              </Button>
            </Box>
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(true);
    getMiSeqRunsById(id)
      .then((response) => {
        setOptionsMultiQC([
          { label: 'Open', url: response.multiqc_report_url },
          { label: 'Save', url: response.multiqc_report_url },
        ]);
        setOptionsHTML([
          { label: 'Open', url: response.summary_report_url },
          { label: 'Save', url: response.summary_report_url },
        ]);
        setOptionsSampleSheet([
          { label: 'Open', url: response.error_rates_combined_url },
          { label: 'Save', url: response.error_rates_combined_url },
        ]);
        setOptionsWebAppInput([
          { label: 'Save', url: response.web_app_input_files_url },
        ]);
        if (response.web_app_input_files_url) setIsWebAppInput(true);
        setAnalysisName(response.analysis_name);
        setSamples(response.samples);
        setDescription(
          response.miseq_run.comment
            ? response.miseq_run.comment
            : 'No comment provided'
        );
        setPreviousDescription(
          response.miseq_run.comment
            ? response.miseq_run.comment
            : 'No comment provided'
        );
        setDate(new Date(response.analysis_date));
        setRunId(response.miseq_run.id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseQuitDialog = () => {
    setOpenQuitDialog(false);
  };

  const handleSaveAndCloseDialog = () => {
    setIsEditMode(false);
    editComment(runId, description);
    setPreviousDescription(description);
    setOpenDialog(false);
    setOpenSnackbar(true);
  };

  const handleEditComment = () => {
    setIsEditMode(true);
  };

  const handleCancelEditComment = () => {
    setOpenQuitDialog(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleQuitDialog = () => {
    setDescription(previousDescription);
    setIsEditMode(false);
    setOpenQuitDialog(false);
  };

  const renderDescription = () => {
    return (
      <Box mb={2} display='flex' flexDirection='row' alignItems='center'>
        {renderConfirmationDialogSave()}
        {renderConfirmationDialogQuit()}
        {renderSnackbar()}
        <Typography color='primary'>Comment: </Typography>{' '}
        {isEditMode ? (
          <>
            <TextField
              id='standard-basic'
              variant='standard'
              size='small'
              autoComplete='off'
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              fullWidth
            />
            <Tooltip title='Save'>
              <IconButton onClick={handleOpenDialog} size='small'>
                <CheckIcon fontSize='small' color='primary' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Cancel'>
              <IconButton onClick={handleCancelEditComment} size='small'>
                <ClearIcon fontSize='small' color='secondary' />
              </IconButton>
            </Tooltip>{' '}
          </>
        ) : (
          <>
            <Typography>{description}</Typography>
            <Tooltip title='Edit comment'>
              <IconButton onClick={handleEditComment} size='small'>
                <EditIcon fontSize='small' color='secondary' />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  const renderConfirmationDialogSave = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Save comment?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This action will save the comment in the database and the previous
            one will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveAndCloseDialog}>Save</Button>
          <Button onClick={handleCloseDialog} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderConfirmationDialogQuit = () => {
    return (
      <Dialog
        open={openQuitDialog}
        onClose={handleCloseQuitDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {'Quit edit comment?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Any alterations will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQuitDialog}>Quit</Button>
          <Button onClick={handleCloseQuitDialog} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSnackbar = () => {
    return (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity='success'
          sx={{ width: '100%' }}>
          Comment saved!
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title={`${analysisName} analysis`}>
          <Box mr={1}>
            <MenuDropdown label='Summary Report' options={optionsHTML} />
          </Box>
          <Box mr={1}>
            <MenuDropdown label='MultiQC Report' options={optionsMultiQC} />
          </Box>
          <Box mr={1}>
            <MenuDropdown
              label='Error Rates Combined'
              options={optionsSampleSheet}
            />
          </Box>
          {isWebAppInput && (
            <MenuDropdown label='Web App Input' options={optionsWebAppInput} />
          )}
        </PageTitle>

        {renderDescription()}

        {date && (
          <Box mb={2} display='flex' flexDirection='row'>
            <Typography color='primary'>Date: </Typography>
            <Typography>{date.toLocaleDateString('fr-FR')}</Typography>
          </Box>
        )}

        <MaterialReactTable
          columns={columns}
          data={samples}
          enableDensityToggle={false}
          enableColumnResizing
          enableStickyHeader
          enableStickyFooter
          state={{ loading }}
          initialState={{
            showColumnFilters: true,
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 100 },
          }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps={{ sx: { maxHeight: 400 } }}
        />
      </AuthenticatedTemplate>
    </>
  );
};

export default MiSeqRunAnalysis;
