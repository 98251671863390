import React, { useEffect, useMemo, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fr';
import MaterialReactTable from 'material-react-table';
import { Link as RouterLink } from 'react-router-dom';
import {
  downloadErrorRatesCombinedFile,
  downloadFile,
  getMiSeqRuns,
} from '../../../services/MiSeqRunsService';
import { findAnalysisByName } from '../../../utils/utils';

const RunsTable = () => {
  const [runs, setRuns] = useState([]);
  //const [error, setError] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getMiSeqRuns()
      .then((response) => {
        setRuns(response);
        setLoading(false);
      })
      .catch((err) => {
        //setError(err);
        setLoading(false);
      });
  }, []);

  const isLessOrEqualThan = (date, filterValue) => {
    let filterDate = new Date(new Date(filterValue).toDateString());
    let dateToFilter = new Date(date.toDateString());
    if (filterValue === null) return true; // To make the "clear" filter work: the "clear" makes the filterValue null.
    if (filterDate < dateToFilter) {
      return false;
    } else {
      return true;
    }
  };

  const columns = useMemo(
    () => [
      {
        header: 'Run',
        accessorKey: 'miseq_run.run_name',
        enableHiding: false,
        enableGrouping: false,
        minSize: '90',
      },
      {
        header: 'Analysis',
        accessorKey: 'analysis_name',
        enableGrouping: false,
        enableHiding: false,
        minSize: '80',
        Cell: ({ cell }) => (
          <RouterLink
            to={`/sequencing-analysis/miseq-runs/${findAnalysisByName(
              runs,
              cell.getValue()
            )}`}
            style={{
              color: '#FF5843',
              fontWeight: 'bold',
              textDecoration: 'inherit',
            }}>
            {cell.getValue()}
          </RouterLink>
        ),
      },
      {
        header: 'Comment',
        accessorKey: 'miseq_run.comment',
        enableGrouping: false,
        enableHiding: false,
        minSize: 150,
        Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      },
      {
        header: 'Date',
        id: 'analysisDate',
        accessorFn: (row) => new Date(row.analysis_date), //convert to Date for sorting and filtering
        filterFn: (row, id, filterValue) =>
          isLessOrEqualThan(row.getValue(id), filterValue),
        enableGrouping: false,
        enableHiding: false,
        minSize: 85,
        Cell: ({ cell }) => (
          <Typography>
            {new Date(cell.getValue()).toLocaleDateString('fr-FR')}
          </Typography>
        ),
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              clearable
              disableFuture
              slotProps={{
                textField: {
                  helperText: 'Filter mode: Less than or Equal to',
                  variant: 'standard',
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },

      {
        header: 'Summary',
        accessorKey: 'summary_report_url',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        maxSize: '80',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box display='flex' flexDirection='row' alignItems='baseline'>
              <MuiLink
                aria-label='open_in_new'
                href={cell.getValue()}
                target='_blank'>
                <OpenInNewIcon color='secondary' />
              </MuiLink>
              <Button
                color='secondary'
                aria-label='download'
                onClick={() => downloadFile(cell.getValue())}>
                <FileDownloadIcon color='secondary' />
              </Button>
            </Box>
          ) : (
            <></>
          ),
      },
      {
        header: 'MultiQC',
        accessorKey: 'multiqc_report_url',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        maxSize: '80',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box display='flex' flexDirection='row' alignItems='baseline'>
              <MuiLink
                aria-label='open_in_new'
                href={cell.getValue()}
                target='_blank'>
                <OpenInNewIcon color='secondary' />
              </MuiLink>
              <Button
                color='secondary'
                aria-label='download'
                onClick={() => downloadFile(cell.getValue())}>
                <FileDownloadIcon color='secondary' />
              </Button>
            </Box>
          ) : (
            <></>
          ),
      },
      {
        header: 'Error rates combined',
        accessorKey: 'error_rates_combined_url',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        maxSize: '80',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box display='flex' flexDirection='row' alignItems='baseline'>
              <MuiLink
                aria-label='open_in_new'
                href={cell.getValue()}
                target='_blank'>
                <OpenInNewIcon color='secondary' />
              </MuiLink>
              <Button
                color='secondary'
                aria-label='download'
                onClick={() => downloadErrorRatesCombinedFile(cell.getValue())}>
                <FileDownloadIcon color='secondary' />
              </Button>
            </Box>
          ) : (
            <></>
          ),
      },
      {
        header: 'Web app input',
        accessorKey: 'web_app_input_files_url',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        maxSize: '77',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Button
              color='secondary'
              aria-label='download'
              onClick={() => downloadFile(cell.getValue())}>
              <FileDownloadIcon color='secondary' />
            </Button>
          ) : (
            <></>
          ),
      },
    ],
    [runs]
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={runs}
      //enableTopToolbar={false}
      enableColumnResizing
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      state={{ loading }}
      initialState={{
        showColumnFilters: true,
        density: 'compact',
        grouping: ['miseq_run.run_name'], //an array of columns to group by by default (can be multiple)
        pagination: { pageIndex: 0, pageSize: 100 },
        sorting: [{ id: 'miseq_run.run_name', desc: true }], //sort by state by default
      }}
      muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      muiTableContainerProps={{ sx: { maxHeight: 700 } }}
      positionToolbarAlertBanner='none'
      enableGlobalFilter={false}
      enableDensityToggle={false}
      renderDetailPanel={({ row }) => (
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='wrap'
          justifyContent='center'>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            width='75%'
            gap='2px'>
            {' '}
            Samples:
            {row.original.samples.map((sample) => (
              <Chip
                label={sample.sample.identifier}
                size='small'
                color='secondary'
                variant='outlined'
              />
            ))}
          </Box>
        </Box>
      )}
    />
  );
};

export default RunsTable;
