import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Grid from '@mui/material/Grid';
import { TOOLS } from '../../data/tools';
import HomepageCard from '../common/HomepageCard';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle';

function ToolsPage() {
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageTitle title='Tools' />
        <Grid container spacing={2}>
          {Object.keys(TOOLS).map((item) => {
            return (
              <HomepageCard
                xs={6}
                url={TOOLS[item].url}
                icon={TOOLS[item].icon}
                linkName={TOOLS[item].title}
                key={TOOLS[item].url}
                openInNewWindow={TOOLS[item].openInNewWindow}
              />
            );
          })}
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
}

export default ToolsPage;
