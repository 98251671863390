import React from 'react';
import ForbiddenPage from '../components/common/ForbiddenPage';

const ProtectedRoute = ({ isAuthorized, children }) => {
  if (!isAuthorized) {
    return <ForbiddenPage />;
  }

  return children;
};

export default ProtectedRoute;
