import React, { useEffect, useState } from 'react';
import {
  useIsAuthenticated,
  useMsal, // useMsalAuthentication,
} from '@azure/msal-react';
import HelpIcon from '@mui/icons-material/Help';
import Logout from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/datahub-logo.png';
import { BUG_FEATURE_REPORT_URL, USER_GUIDE_URL } from '../../utils/constants';
import './navbar.css';

let activeClassName = 'active-link';
let inactiveClassName = 'inactive-link';

const NavBar = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userName, setUserName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElHelp, setAnchorElHelp] = useState(null);

  const handleSignIn = () => {
    instance.loginRedirect({
      scopes: ['user.read'],
    });
  };

  const handleSignOut = () => {
    instance.logoutRedirect();
  };

  const handleLoginMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpMenu = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleCloseLoginMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseHelpMenu = () => {
    setAnchorElHelp(null);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const currentAccount = instance.getActiveAccount();
      if (currentAccount) setUserName(currentAccount.name);
    }
  }, [instance, isAuthenticated]);

  return (
    <AppBar
      position='fixed'
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <img src={logo} height='45' alt='datahub-logo'></img>
        <Box display='flex' alignItems='baseline' gap={3} ml={5}>
          <NavLink
            to='/'
            className={({ isActive }) =>
              isActive ? activeClassName : inactiveClassName
            }>
            Home
          </NavLink>
        </Box>
        <Box flexGrow='1' />
        <Box display='flex' alignItems='center' gap={3}>
          {isAuthenticated ? (
            <>
              <Tooltip title='Help'>
                <IconButton onClick={handleHelpMenu}>
                  <HelpIcon style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>

              <Menu
                id='menu-help'
                anchorEl={anchorElHelp}
                keepMounted
                open={Boolean(anchorElHelp)}
                onClose={handleCloseHelpMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem>
                  <Link
                    href={USER_GUIDE_URL}
                    target='_blank'
                    rel='noreferrer'
                    underline='none'
                    color='secondary'>
                    User guide
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={BUG_FEATURE_REPORT_URL}
                    target='_blank'
                    rel='noreferrer'
                    underline='none'
                    color='secondary'>
                    Report a bug
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={BUG_FEATURE_REPORT_URL}
                    target='_blank'
                    rel='noreferrer'
                    underline='none'
                    color='secondary'>
                    Request a feature
                  </Link>
                </MenuItem>
              </Menu>
              <Button
                onClick={handleLoginMenu}
                color='inherit'
                label='basic-button'>
                {userName}
              </Button>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseLoginMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  sx: { width: anchorEl && anchorEl.offsetWidth },
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button onClick={handleSignIn} color='inherit'>
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
