import React, { useEffect, useState } from 'react';
import ProtectedRoute from './authentication/ProtectedRoute';
import { useAccount, useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NgsDbChanges from './components/dbChanges/NgsDbChanges';
import SynthesisDbChanges from './components/dbChanges/SynthesisDbChanges';
import Gel from './components/gels/GelPage';
import Homepage from './components/home/Homepage';
import NavBar from './components/layout/NavBar';
import SequencingAnalysisPage from './components/sequencingAnalysis/SequencingAnalysisPage';
import MiSeqRunAnalysis from './components/sequencingAnalysis/miseqRuns/MiSeqRunAnalysis';
import MiSeqRuns from './components/sequencingAnalysis/miseqRuns/MiSeqRuns';
import SequencingRun from './components/sequencingRuns/SequencingRunPage';
import SequencingRuns from './components/sequencingRuns/SequencingRunsPage';
import ToolsPage from './components/tools/ToolsPage';
import UploadGeneAssemblySS from './components/uploadData/uploadGeneAssemblySs';
import './App.css';


const App = () => {
  // Set state and define constants
  const { instance } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const [isSynthesisGroup, setIsSynthesisGroup] = useState(false);
  const [isNgsGroup, setIsNgsGroup] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  // Lifecycle
  // Check user groups and set state
  useEffect(() => {
    setUserGroups(account?.idTokenClaims?.groups);
    setIsNgsGroup(
      account &&
        account.idTokenClaims?.groups?.includes(
          `${process.env.REACT_APP_NGS_DB_CHANGES_GROUP}`
        )
    );
    setIsSynthesisGroup(
      account &&
        account.idTokenClaims?.groups?.includes(
          `${process.env.REACT_APP_SYNTHESIS_DB_CHANGES_GROUP}`
        )
    );
  }, [account]);

  return (
    <BrowserRouter>
      <NavBar />
      <Box className='main-container'>
        <Routes>
          <Route path='/' element={<Homepage userGroups={userGroups} />} />
          <Route path='/gels' element={<Gel />} />
          <Route path='/sequencing-runs/:id' element={<SequencingRun />} />
          <Route path='/sequencing-runs' element={<SequencingRuns />} />
          <Route
            path='/sequencing-analysis'
            element={<SequencingAnalysisPage />}
          />
          <Route path='/tools' element={<ToolsPage />} />
          <Route
            path='/sequencing-analysis/miseq-runs'
            element={<MiSeqRuns />}
          />
          <Route
            path='/sequencing-analysis/miseq-runs/:id'
            element={<MiSeqRunAnalysis />}
          />
          <Route
            path='/ngs-db-changes'
            element={
              <ProtectedRoute isAuthorized={isNgsGroup}>
                <NgsDbChanges />
              </ProtectedRoute>
            }
          />
          <Route
            path='/synthesis-db-changes'
            element={
              <ProtectedRoute isAuthorized={isSynthesisGroup}>
                <SynthesisDbChanges />
              </ProtectedRoute>
            }
          />
          <Route
            path='/tools/upload-gene-assembly-samplesheet'
            element={<UploadGeneAssemblySS />}
          />
        </Routes>
      </Box>
    </BrowserRouter>
  );
};

export default App;