import { createTheme } from '@mui/material/styles';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

//import vars from '../assets//_variables-mui.scss';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: vars.primary,
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2,
    },
    secondary: {
      main: vars.secondary,
    },
    error: {
      main: vars.red,
    },
    success: {
      main: vars.green,
    },
    warning: {
      main: vars.orange,
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedPrimary: {
        backgroundColor: vars.secondary,
        '&:hover': {
          backgroundColor: 'rgb(51, 62, 101)',
        },
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeLarge: {
        fontSize: '16px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '16px',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.second,
      },
    },
    MuiInput: {
      underline: {
        '&$disabled': {
          '&:before': {
            borderBottomStyle: 'solid',
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '35px',
      lineHeight: '40px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '29px',
      lineHeight: '32px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '25px',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    button: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '12px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
});

export default MuiTheme;
