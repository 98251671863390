import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const BackButton = ({ children, ...otherProps }) => {
  const navigate = useNavigate();

  return (
    <Link
      {...otherProps}
      onClick={(event) => {
        event.preventDefault();
        navigate(-1);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      component={RouterLink}>
      <ArrowBackIcon sx={{ mr: 0.5 }} />
      {children || 'Back'}
    </Link>
  );
};

export default BackButton;
