import React, { forwardRef, useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useAccount, useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Papa from 'papaparse';
import { triggerLambda, uploadCsvDataToS3 } from '../../services/AwsService';
import {
  getAuthorizedPlateTags,
  getPlateNames,
} from '../../services/PlateTagService.js';
import { addMetadata } from '../../utils/formatData.js';
import Breadcrumbs from '../common/Breadcrumbs';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CustomGridToolbar = ({
  setOpenNewRow,
  setUploadedFile,
  rowSelectionModel,
  setOpenValueDialog,
  setOpenDeleteRowsDialog,
}) => {
  const handleClickAddRow = () => {
    setOpenNewRow(true);
  };

  const handleUploadFile = (event) => {
    setUploadedFile(event.target.files[0]);
  };
  const handleEditRows = () => {
    setOpenValueDialog(true);
  };
  const handleDeleteSelectedRows = () => {
    setOpenDeleteRowsDialog(true);
  };
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <Button
          variant='contained'
          size='small'
          startIcon={<AddIcon />}
          onClick={handleClickAddRow}>
          Add row
        </Button>
        <Button
          component='label'
          variant='outlined'
          size='small'
          startIcon={<CloudUploadIcon />}>
          Upload file
          <VisuallyHiddenInput type='file' onChange={handleUploadFile} />
        </Button>
        <Box sx={{ flexGrow: 1, mb: 5 }} />
        <Fab
          variant='extended'
          color='secondary'
          size='small'
          onClick={handleEditRows}
          disabled={rowSelectionModel.length < 1}>
          <EditIcon />
          Edit rows
        </Fab>
        <Fab
          variant='extended'
          color='error'
          size='small'
          onClick={handleDeleteSelectedRows}
          disabled={rowSelectionModel.length < 1}>
          <DeleteIcon />
          Delete rows
        </Fab>
      </GridToolbarContainer>

      <Divider />
    </>
  );
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const SynthesisDbChanges = () => {
  const { instance } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const vertical = 'top';
  const horizontal = 'right';
  // States
  // Control opening and closing of dialogs and snackbars
  const [openValueDialog, setOpenValueDialog] = useState(false);
  const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openNewRow, setOpenNewRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openDeleteRowsDialog, setOpenDeleteRowsDialog] = useState(false);
  // Uploaded file
  const [uploadedFile, setUploadedFile] = useState(null);
  // Plate_tag values to change in bulk
  const [valueToChange, setValueToChange] = useState([]);
  // Data for the grid
  const [rows, setRows] = useState([]);
  // Response from lambda
  const [response, setResponse] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Selected rows
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // Data for dropdowns
  const [plateTags, setPlateTags] = useState([]);
  const [plateNames, setPlateNames] = useState([]);
  // Other values
  const [newRowPlateName, setNewRowPlateName] = useState(null);
  const [newRowPlateTags, setNewRowPlateTags] = useState([]);
  // Row to edit when editing a single row
  const [rowToEdit, setRowToEdit] = useState(null);
  const [fieldsToEdit, setFieldsToEdit] = useState([]);
  // Selected option: add, replace or remove
  const [option, setOption] = useState(null);
  const options = [
    { label: 'Add', value: 'add' },
    { label: 'Replace', value: 'replace' },
    { label: 'Remove', value: 'remove' },
  ];

  // Delete row inline
  const handleClickDeleteRow = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  // Edit row inline
  const handleClickEditRow = (id) => () => {
    const row = rows.find((row) => row.id === id);
    setRowToEdit(row);
    setOpenEditRow(true);
  };

  const columns = [
    {
      field: 'plate_name',
      flex: 1,
      renderHeader: () => <strong>Plate name</strong>,
    },
    {
      field: 'plate_tag',
      flex: 1,
      renderHeader: () => <strong>Plate tag(s)</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Actions</strong>,
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleClickEditRow(id)}
            color='secondary'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={handleClickDeleteRow(id)}
            color='error'
          />,
        ];
      },
    },
  ];

  // Set plate tags
  useEffect(() => {
    setLoading(true);
    getAuthorizedPlateTags().then((res) => {
      if (res.includes(null)) {
        res.pop(); // remove the 'null' value at end of array
        const plateTagsArray = res.map((obj) => {
          return obj.plateTag;
        });
        plateTagsArray.push('null'); // re-add the 'null' value at end of array
        setPlateTags(plateTagsArray);
        setLoading(false);
      }
    });
  }, []);

  // Set plate names
  useEffect(() => {
    setLoading(true);
    getPlateNames().then((res) => {
      const plateNamesArray = res.map((obj) => {
        return obj.plate_name;
      });
      setPlateNames(plateNamesArray);
      setLoading(false);
    });
  }, []);

  // Parse the uploaded file
  useEffect(() => {
    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.forEach((row) => {
            const id = randomId();
            setRows((oldRows) => [
              ...oldRows,
              {
                id,
                plate_name: row['plate_name'],
                plate_tag: row['plate_tag'].split(';'),
              },
            ]);
          });
        },
      });
    }
  }, [uploadedFile]);

  // Open lambdaResponseSnackbar when response is set
  useEffect(() => {
    if (response) setOpenSnackbar(true);
  }, [response]);

  // Change values in bulk
  const handleChangeValuesButton = () => {
    const newRows = [...rows];
    rowSelectionModel.forEach((rowId) => {
      const row = newRows.find((row) => row.id === rowId);
      row['plate_tag'] = valueToChange;
    });
    setRows(newRows);
    setOpenValueDialog(false);
  };

  // Add new row
  const handleAddNewRow = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        plate_name: newRowPlateName,
        plate_tag: newRowPlateTags,
      },
    ]);
    setOpenNewRow(false);
  };

  // Edit row: plate name
  const handleChangePlateName = (value) => {
    const newFieldsToEdit = [...fieldsToEdit];
    newFieldsToEdit.push('plate_name');
    setFieldsToEdit(newFieldsToEdit);
    const row = rows.find((row) => row.id === rowToEdit.id);
    if (!fieldsToEdit.includes('plate_tag')) {
      setNewRowPlateTags(row.plate_tag);
    }
    setNewRowPlateName(value);
  };

  // Edit row: plate tags
  const handleChangePlateTags = (value) => {
    const newFieldsToEdit = [...fieldsToEdit];
    newFieldsToEdit.push('plate_tag');
    setFieldsToEdit(newFieldsToEdit);
    const row = rows.find((row) => row.id === rowToEdit.id);
    if (!fieldsToEdit.includes('plate_name')) {
      setNewRowPlateName(row.plate_name);
    }
    setNewRowPlateTags(value);
  };

  // Edit row: submit
  const handleEditRow = () => {
    const newRows = [...rows];
    const row = newRows.find((row) => row.id === rowToEdit.id);
    row['plate_name'] = newRowPlateName;
    row['plate_tag'] = newRowPlateTags;
    setRows(newRows);
    setOpenEditRow(false);
    setFieldsToEdit([]);
  };

  // Edit selected rows in bulk
  const handleEditSelectedRows = (event) => {
    const {
      target: { value },
    } = event;
    setValueToChange(typeof value === 'string' ? value.split(',') : value);
  };

  // Delete selected rows in bulk
  const handleDeleteSelectedRows = () => {
    const newRows = rows.filter((row) => !rowSelectionModel.includes(row.id));
    setRows(newRows);
    setOpenDeleteRowsDialog(false);
  };

  // Submit changes to the database
  const handleClickSubmit = () => {
    console.debug('DB:', `${process.env.REACT_APP_DB_NAME}`);
    console.debug('rows:', rows);
    console.debug('option:', option);
    setOpenConfirmSubmit(true);
  };

  // Submit changes to the database
  const handleConfirmSubmit = () => {
    const fileKey = `datahub/update_plate_tags_${Date.now()}.csv`;
    setLoading(true);
    setOpenLoader(true);
    const newRows = addMetadata(rows, account.name, option);
    uploadCsvDataToS3(newRows, 'ds-data-consolidation', fileKey).then((res) => {
      console.debug(res);
      if (res.upload_status === 'ok') {
        console.debug('upload successfull');
        triggerLambda('data_consolidation_lambda', {
          eventType: 'synthesis_db_changes_run',
          body: {
            bucketName: 'ds-data-consolidation',
            fileKey: res.file_key,
            method: option,
            database: `${process.env.REACT_APP_DB_NAME}`,
          },
        }).then((r) => {
          setLoading(false);
          if (r.statusCode === 200) {
            console.debug('lambda successfull');
            setResponse({
              status: 'ok',
              message: 'Data successfully submited',
            });
          } else {
            console.debug('lambda error');
            setResponse({
              status: 'error',
              message: 'Error during data validation',
            });
          }
        });
      } else {
        setLoading(false);
        setResponse({
          status: 'error',
          message: 'Error submitting file',
        });
      }
    });
    setOpenConfirmSubmit(false);
  };

  // Smaller components
  const editValueDialog = (
    <Dialog open={openValueDialog} fullWidth maxWidth='sm'>
      <DialogTitle id='alert-dialog-title'>
        Set the value for <strong>plate_tag</strong> for the{' '}
        {rowSelectionModel.length} selected rows
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Plate tag</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              multiple
              value={valueToChange}
              defaultValue='null'
              label='plate tag'
              onChange={handleEditSelectedRows}
              input={<OutlinedInput label='Plate tag' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}>
              {plateTags.map((plateTag) => (
                <MenuItem value={plateTag} key={plateTag}>
                  <Checkbox checked={valueToChange.indexOf(plateTag) > -1} />
                  <ListItemText primary={plateTag} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant='outlined'
          size='small'
          onClick={() => setOpenValueDialog(false)}>
          Close
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={handleChangeValuesButton}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );

  const newRowDialog = (
    <Dialog open={openNewRow} fullWidth maxWidth='sm'>
      <DialogTitle id='alert-dialog-title'>
        <strong>New row</strong>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 120 }} mb={3}>
          <Autocomplete
            id='plate-names'
            options={plateNames}
            fullWidth
            onChange={(event, value) => setNewRowPlateName(value)}
            renderInput={(params) => (
              <TextField {...params} label='Plate name' />
            )}
          />
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <Autocomplete
            multiple
            fullWidth
            id='plate-tags'
            options={plateTags}
            disableCloseOnSelect
            onChange={(event, value) => setNewRowPlateTags(value)}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label='Plate tags' />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant='outlined'
          size='small'
          onClick={() => setOpenNewRow(false)}>
          Close
        </Button>
        <Button variant='contained' size='small' onClick={handleAddNewRow}>
          Add new row
        </Button>
      </DialogActions>
    </Dialog>
  );

  const editRowDialog = (
    <Dialog open={openEditRow} fullWidth maxWidth='sm'>
      <DialogTitle id='edit-row-dialog-title'>
        <strong>Edit row</strong>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 120 }} mb={3}>
          <Autocomplete
            id='plate-names'
            options={plateNames}
            value={rowToEdit ? rowToEdit.plate_name : null}
            fullWidth
            onChange={(event, value) => handleChangePlateName(value)}
            renderInput={(params) => (
              <TextField {...params} label='Plate name' />
            )}
          />
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <Autocomplete
            multiple
            fullWidth
            id='plate-tags'
            options={plateTags}
            defaultValue={rowToEdit ? rowToEdit.plate_tag : []}
            disableCloseOnSelect
            onChange={(event, value) => handleChangePlateTags(value)}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label='Plate tags' />
            )}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant='outlined'
          size='small'
          onClick={() => setOpenEditRow(false)}>
          Close
        </Button>
        <Button variant='contained' size='small' onClick={handleEditRow}>
          Edit row
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmDeleteRowsDialog = (
    <Dialog open={openDeleteRowsDialog} fullWidth maxWidth='xs'>
      <DialogTitle id='alert-dialog-title'>Confirm delete rows</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to delete the {rowSelectionModel.length}{' '}
          selected rows? <br />
          <strong>Note:</strong> This action will not delete the rows from the
          database.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setOpenDeleteRowsDialog(false);
          }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={handleDeleteSelectedRows}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmSubmitDialog = (
    <Dialog open={openConfirmSubmit} fullWidth maxWidth='xs'>
      <DialogTitle id='alert-dialog-title'>Confirm submit</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to submit your changes to the database?
          <br />
          <strong>Note:</strong> If you submit two rows with the same plate
          name, only one of them will be kept.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            setOpenConfirmSubmit(false);
          }}>
          Cancel
        </Button>
        <Button onClick={handleConfirmSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );

  const lambdaResponseSnackbar = (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={() => setOpenSnackbar(false)}>
      <Alert
        onClose={() => setOpenSnackbar(false)}
        severity={response && response.status === 'ok' ? 'success' : 'error'}
        sx={{ width: '100%' }}>
        {response && response.message}
      </Alert>
    </Snackbar>
  );

  const loader = (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openLoader}>
      <CircularProgress />
    </Backdrop>
  );

  // Main return
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title={`Synthesis DB changes`}>
          <Box>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClickSubmit}
              disabled={!option}>
              Submit
            </Button>
          </Box>
        </PageTitle>
        <>
          {editValueDialog}
          {newRowDialog}
          {editRowDialog}
          {confirmSubmitDialog}
          {confirmDeleteRowsDialog}
          {lambdaResponseSnackbar}
          {loading && loader}
          <Box display='flex' justifyContent='flex-end'>
            <FormControl>
              <FormLabel id='option-group-label'>Global option</FormLabel>
              <RadioGroup
                row
                aria-labelledby='option-radio-buttons-group-label'
                name='opton-radio-buttons-group'
                onChange={(event) => setOption(event.target.value)}>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ height: 600, width: '100%' }} mt={1}>
            <DataGridPremium
              slots={{ toolbar: CustomGridToolbar }}
              density='compact'
              rows={rows}
              columns={columns}
              experimentalFeatures={{ clipboardPaste: true }}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              slotProps={{
                toolbar: {
                  setRows,
                  setOpenNewRow,
                  setUploadedFile,
                  rowSelectionModel,
                  setOpenValueDialog,
                  setOpenDeleteRowsDialog,
                },
              }}
            />
          </Box>
        </>
      </AuthenticatedTemplate>
    </>
  );
};

export default SynthesisDbChanges;
