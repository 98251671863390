import React from 'react';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import Root from './components/Root';
import './index.css';

LicenseInfo.setLicenseKey(
  '8f7a934e0dc14fa03b4c9a870569e313Tz03NDIyMyxFPTE3MjU3MTYzNzcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

reportWebVitals();
