import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { downloadFile } from '../../services/MiSeqRunsService';

const MenuDropdown = (props) => {
  const { label, options } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    options && (
      <>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          endIcon={<KeyboardArrowDownIcon />}
          variant='contained'
          disableElevation
          color='secondary'
          size='small'
          onClick={handleClick}>
          {label}
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {options.map((option) => {
            return (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  if (option.label === 'Open') {
                    window.open(option.url, '_blank');
                  } else if (option.label === 'Save') {
                    downloadFile(option.url);
                  }
                }}
                key={option.label}>
                {option.label}
                <Box ml={2}>
                  {option.label === 'Open' ? (
                    <OpenInNewIcon fontSize='small' color='secondary' />
                  ) : (
                    <FileDownloadIcon fontSize='small' color='secondary' />
                  )}
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    )
  );
};

export default MenuDropdown;
