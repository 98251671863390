import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PageTitle({ title, stepper, children }) {
  const otherProps = stepper ? { flexGrow: 1 } : {};
  return (
    <Box mb={3} display='flex' justifyContent='space-between'>
      <Typography variant='h3'>{title}</Typography>
      <Box display='flex' justifyContent='space-between' {...otherProps}>
        {children}
      </Box>
    </Box>
  );
}

export default PageTitle;
