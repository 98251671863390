export async function uploadSampleSheetToTempBucket(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('bucket', 'ds-ga-samplesheets');
  formData.append('checkIfExists', false);
  console.debug('>>>uploadSampleSheetToTempBucket', file);
  const requestOptions = {
    method: 'POST',
    body: formData,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/storage/uploadFile`,
    requestOptions
  );
  const data = await response.json();
  console.debug('<<<uploadSampleSheetToTempBucket', data);
  return data;
}

export async function uploadSampleSheetToFinalBucket(file) {
  console.debug('>>>uploadSampleSheetToFinalBucket', file);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('key', 'synology/ngs/miseq_pipeline/samplesheets');
  formData.append('bucket', 'ds-data-dropbox');
  formData.append('checkIfExists', false);
  console.debug('>>>uploadSampleSheetToFinalBucket', formData);
  const requestOptions = {
    method: 'POST',
    body: formData,
  };
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/storage/uploadFile`,
    requestOptions
  );
  const data = await response.json();
  console.debug('<<<uploadSampleSheetToFinalBucket', data);
  return data;
}

export async function validateSampleSheet(file) {
  console.debug('>>>validateSampleSheet', file);
  const params = { functionName: 'assembly_samplesheet_trigger', key: file };
  const queryStr = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/geneAssemblySampleSheet/validate?${queryStr}`
  );
  const data = await response.json();
  console.debug('<<<validateSampleSheet', data);
  return data;
}

export async function deleteSampleSheet(file) {
  console.debug('>>>deleteSampleSheet', file);
  const params = { bucket: 'ds-ga-samplesheets', key: file };
  const queryStr = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/geneAssemblySampleSheet/delete?${queryStr}`
  );
  // TODO HANDLE ERRORS
  // const data = await response.json();
  console.debug('<<<deleteSampleSheet', response);
  // return data;
}
