import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';

const HomepageCard = (props) => {
  const { url, icon, linkName, xs, openInNewWindow } = props;

  return (
    <Grid item xs={xs}>
      <Paper elevation={3}>
        <Link
          href={url}
          underline='hover'
          variant='overline'
          {...(openInNewWindow ? { target: '_blank' } : {})}>
          <Box
            pt={5}
            pb={5}
            display='flex'
            flexDirection='column'
            alignItems='center'>
            {icon && <img src={icon} height='75' alt={`${icon}`} />}
            {linkName}
          </Box>
        </Link>
      </Paper>
    </Grid>
  );
};

export default HomepageCard;
