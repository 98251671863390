import React from 'react';
import Typography from '@mui/material/Typography';

const UnauthenticatedPage = (props) => {
  return (
    <Typography>
      Please login to have access to the content of this page.
    </Typography>
  );
};

export default UnauthenticatedPage;
