//import gels from '../assets/images/gels.png';
import report from '../assets/images/oligo-ngs-report.png';
//import op2 from '../assets/images/op2-data.png';
import seqAnalysis from '../assets/images/sequencing-analysis.png';
//import seqRuns from '../assets/images/sequencing-runs.png';
import tools from '../assets/images/tools.png';
import dbUpload from '../assets/images/upload-to-DB.png';

export const HOME_PAGE_ITEMS = {
  /*SEQUENCING_RUNS: {
    icon: seqRuns,
    title: 'Sequencing runs',
    url: '/sequencing-runs',
  },*/
  SEQUENCING_ANALYSIS: {
    icon: seqAnalysis,
    title: 'Sequencing analysis',
    url: '/sequencing-analysis',
  },
  TOOLS: { icon: tools, title: 'Tools', url: '/tools' },
  /*OP2_DATA: {
    icon: op2,
    title: 'OP2 data',
    url: '/analysis/op2.html',
  },*/
  NGS_REPORT: {
    icon: report,
    title: 'Oligo NGS report',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5d247b46-5bb6-4688-ba71-123cb8ed0db6&autoAuth=true&ctid=33033ca8-e018-49eb-be9c-e03dd0f640b0',
  },
  SYNTHESIS_REPORT: {
    icon: report,
    title: 'Synthesis report',
    url: 'https://app.powerbi.com/reportEmbed?reportId=589c0c27-e664-43d7-9063-544453f55d6c&autoAuth=true&ctid=33033ca8-e018-49eb-be9c-e03dd0f640b0',
  },
  //GELS: { icon: gels, title: 'Gels', url: '/gels' },
  NGS_DB_CHANGES: {
    icon: dbUpload,
    title: 'Upload changes to NGS DB',
    url: '/ngs-db-changes',
  },
  SYNTHESIS_DB_CHANGES: {
    icon: dbUpload,
    title: 'Upload changes to Synthesis DB',
    url: '/synthesis-db-changes',
  },
};
