import { convertJSONToCSV } from '../utils/formatData';

export async function uploadCsvDataToS3(jsonObject, bucket, fileKey) {
  console.debug('>>>uploadCsvDataToS3', fileKey, 'in bucket', bucket);
  console.debug('jsonObject', jsonObject);
  const csvData = convertJSONToCSV(jsonObject);
  const formData = new FormData();
  formData.append('csvData', csvData);
  formData.append('bucketName', bucket);
  formData.append('fileKey', fileKey);
  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/storage/uploadCsvData`,
    requestOptions
  );
  const data = await response.json();
  console.debug('<<<uploadCsvDataToS3', data);
  return data;
}

export async function triggerLambda(functionName, payload) {
  console.debug('>>>triggerLambda', functionName, 'with payload', payload);
  const formData = new FormData();
  formData.append('functionName', functionName);
  formData.append('payload', JSON.stringify(payload));
  const requestOptions = {
    method: 'POST',
    body: formData,
  };
  const response = await await fetch(
    `${process.env.REACT_APP_BASE_URL}/lambda/callFunction`,
    requestOptions
  );
  const data = await response.json();
  console.debug('<<<triggerLambda', data);
  return data;
}
