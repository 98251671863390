import React from 'react';
import RunsTable from './RunsTable';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '../../common/Breadcrumbs';
import UnauthenticatedPage from '../../common/UnauthenticatedPage.jsx';
import PageTitle from '../../layout/PageTitle';

const MiSeqRuns = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title={`Gene assembly NGS analysis`}>
          <Button
            variant='contained'
            color='secondary'
            href='https://data.dshub.io/analysis/miseq_analysis_tree.html'>
            Older analysis here
          </Button>
        </PageTitle>
        <RunsTable />
      </AuthenticatedTemplate>
    </>
  );
};

export default MiSeqRuns;
