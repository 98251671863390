import React, { useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Box from '@mui/material/Box';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Link } from 'react-router-dom';
import { formatDataForTable } from '../../utils/formatData.js';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle.jsx';

const columnsDefinition = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    flex: 1,
    renderCell: (params) => (
      <Link to={`/sequencing-runs/${params.id}`}>{params.id}</Link>
    ),
  },
];

const SequencingRuns = () => {
  const [data, setData] = useState([]);
  const fetchData = () => {
    var headers = new Headers();
    var username = 'analysis';
    var password = 'dnascript';

    headers.append('Authorization', 'Basic ' + btoa(username + ':' + password));
    fetch('/runs.json', { headers: headers })
      .then((response) => response.json())
      .then((responseJson) => {
        const formattedData = formatDataForTable(responseJson.runs);

        setData(formattedData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageTitle title='Sequencing runs' />
        <Box sx={{ height: '75vh', width: '100%' }}>
          <DataGridPremium
            rows={data}
            columns={columnsDefinition}
            autoPageSize
            disableSelectionOnClick
          />
        </Box>
      </AuthenticatedTemplate>
    </>
  );
};

export default SequencingRuns;
