import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import BackButton from '../common/BackButton';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle';

const SequencingRun = () => {
  let { id } = useParams();
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={1}>
          <BackButton />
        </Box>
        <PageTitle title={`Sequencing run - ${id.toString()}`} />
      </AuthenticatedTemplate>
    </>
  );
};

export default SequencingRun;
