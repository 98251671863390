import { saveAs } from 'file-saver';

export async function getMiSeqRuns() {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/miseqrun-analysis`
  );
  const responseJson = await response.json();
  return responseJson;
}

export async function getMiSeqRunsById(runId) {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/miseqrun-analysis/${runId}`
  );
  const responseJson = await response.json();
  return responseJson;
}

export async function downloadFile(url) {
  let splitUrl = url.split('/');
  let runId = splitUrl[splitUrl.length - 2];
  let fileName = splitUrl[splitUrl.length - 1];
  await fetch(
    `${process.env.REACT_APP_BASE_URL}/miseqrun-analysis/${runId}/download/${fileName}`
  ).then((response) => {
    response.blob().then((blob) => {
      saveAs(blob, fileName);
    });
  });
}

export async function downloadErrorRatesCombinedFile(url) {
  let splitUrl = url.split('/');
  let runId = splitUrl[splitUrl.length - 3];
  let fileName = splitUrl[splitUrl.length - 1];
  await fetch(
    `${process.env.REACT_APP_BASE_URL}/miseqrun-analysis/${runId}/download/baserr/${fileName}`
  ).then((response) => {
    response.blob().then((blob) => {
      saveAs(blob, fileName);
    });
  });
}

export async function editComment(runId, newComment) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ comment: newComment }),
  };
  await fetch(
    `${process.env.REACT_APP_BASE_URL}/miseqRuns/${runId}`,
    requestOptions
  );
}
