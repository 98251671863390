import React, { useCallback, useMemo } from 'react';
import './dropzone.scss';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';

const Dropzone = (props) => {
  const { acceptedExtentions, messageToDisplay, maxFiles, disabled, setFile } =
    props;

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);
  const {
    //acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedExtentions,
    maxFiles: maxFiles,
    onDrop,
    disabled: disabled,
  });

  const className = useMemo(() => {
    return isFocused
      ? 'dropzone focused'
      : isDragAccept
      ? 'dropzone accept'
      : isDragReject
      ? 'dropzone reject'
      : 'dropzone';
  }, [isFocused, isDragAccept, isDragReject]);

  return (
    <Box className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography>{messageToDisplay}</Typography>
    </Box>
  );
};

export default Dropzone;
