import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import miseqicon from '../../assets/images/MiSeq.png';
import iseqicon from '../../assets/images/iSeq.jpg';
import Breadcrumbs from '../common/Breadcrumbs';
import HomepageCard from '../common/HomepageCard';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle';

const SEQUENCING_ANALYSIS_ITEMS = {
  MISEQ: {
    icon: miseqicon,
    title: 'Gene assembly NGS analysis',
    url: '/sequencing-analysis/miseq-runs',
  },
  OLIGO: {
    icon: iseqicon,
    title: 'Oligo analysis runs',
    url: 'https://data.dshub.io/analysis/oligo_analysis_tree.html',
  },
};

function SequencingAnalysisPage() {
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title='Sequencing analysis' />
        <Grid container spacing={2}>
          {Object.keys(SEQUENCING_ANALYSIS_ITEMS).map((item) => {
            return (
              <HomepageCard
                xs={6}
                url={SEQUENCING_ANALYSIS_ITEMS[item].url}
                icon={SEQUENCING_ANALYSIS_ITEMS[item].icon}
                linkName={SEQUENCING_ANALYSIS_ITEMS[item].title}
                key={SEQUENCING_ANALYSIS_ITEMS[item].url}
              />
            );
          })}
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
}

export default SequencingAnalysisPage;
