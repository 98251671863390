import { saveAs } from 'file-saver';

export async function downloadFile(url) {
  let splitUrl = url.split('/');
  let sampleId = splitUrl[splitUrl.length - 2];
  let fileName = splitUrl[splitUrl.length - 1];
  let runId = splitUrl[splitUrl.length - 4];
  await fetch(
    `${process.env.REACT_APP_BASE_URL}/run/${runId}/samples/${sampleId}/download/${fileName}`
  ).then((response) => {
    response.blob().then((blob) => {
      saveAs(blob, fileName);
    });
  });
}
