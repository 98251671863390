import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import MuiTheme from '../providers/Theme.js';
import App from '../App';
import { msalConfig } from '../authentication/msalConfig';

const pca = new PublicClientApplication(msalConfig);
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS)
    pca.setActiveAccount(event.payload.account);
});

const Root = () => {
  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <CssBaseline />
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </ThemeProvider>
    </>
  );
};

export default Root;
