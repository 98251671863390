export const TOOLS = {
  GA_SS_UPLOADER: {
    title: 'Gene Assembly Sample Sheet Uploader',
    url: '/tools/upload-gene-assembly-samplesheet',
    openInNewWindow: false,
  },
  REAGENT_LOOKUP: {
    title: 'Reagent Lookup Tool',
    url: 'https://reagentlookup.dshub.io/',
    openInNewWindow: true,
  },
  SYNTHESIS_FILE_UPLOADER: {
    title: 'Synthesis file uploader',
    url: 'https://synfileuploader.dshub.io/',
    openInNewWindow: true,
  },
  QPCR: {
    title: 'qPCR Analysis Tool',
    url: 'https://qpcrpipelines.dshub.io/',
    openInNewWindow: true,
  },
  OLIGO_DESIGN: {
    title: 'Oligo Design Tool',
    url: 'https://oligodesign.dshub.io',
    openInNewWindow: true,
  },
  GENE_ERROR: {
    title: 'Gene Error Analysis Tool',
    url: 'http://15.237.52.99/',
    openInNewWindow: true,
  },
  BEADS_CV: {
    title: 'BeadsCV app',
    url: 'https://beadscv.dshub.io/',
    openInNewWindow: true,
  },
  OP3: {
    title: 'OP3 tool',
    url: 'https://dnascriptco.sharepoint.com/sites/data_science/Bibliothque/Forms/AllItems.aspx?id=%2Fsites%2Fdata%5Fscience%2FBibliothque%2FDocuments%2F05%2E%20Software%2FOP3&p=true&ga=1',
    openInNewWindow: true,
  },
  PURITY: {
    title: 'Purity prediction tool',
    url: 'https://purityprediction.dshub.io/',
    openInNewWindow: true,
  },
  BLAST_SERVER: {
    title: 'Blast server',
    url: 'https://blastserver.dshub.io/',
    openInNewWindow: true,
  },
  CHAT_DNA: {
    title: 'ChatDNA',
    url: 'https://chatdna.azurewebsites.net/',
    openInNewWindow: true,
  },
  CHAT_PDF: {
    title: 'ChatPDF',
    url: 'https://chatpdf.dshub.io/',
    openInNewWindow: true,
  },
};
