import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/unauthorized.png';

const ForbiddenPage = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant="body1" gutterBottom>
        You do not have the permissions to access this page
      </Typography>
      <img src={icon} height='200' alt={`${icon}`} />
      <Link to='/' style={{ color: '#FF5843', textDecoration: 'none' }}>
        {'< Return to homepage'}
      </Link>
    </Box>
  );
};

export default ForbiddenPage;
