import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useAccount, useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Papa from 'papaparse';
import { triggerLambda, uploadCsvDataToS3 } from '../../services/AwsService';
import { addMetadata, formatInputData } from '../../utils/formatData.js';
import Breadcrumbs from '../common/Breadcrumbs';
import Dropzone from '../common/Dropzone';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';
import PageTitle from '../layout/PageTitle';

const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const NgsDbChanges = () => {
  const { instance } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fieldToChange, setFieldToChange] = useState(null);
  const [valueToChange, setValueToChange] = useState(null);
  const [method, setMethod] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasValueChanged, setHasValueChanged] = useState(false);
  const vertical = 'top';
  const horizontal = 'right';
  const steps = ['Choose field to change', 'Upload file', 'Change data'];
  const fields = [
    'consolidation_status',
    'consolidation_name',
    'condition1',
    'condition2',
    'condition3',
    'special',
  ];
  const consolidation_name_placeholder =
    'consolidation_name1;consolidation_name2;...';
  const condition_placeholder = 'New value';
  // TODO: read columns dynamically from the file
  const columns = useMemo(
    () => [
      {
        field: 'ngs_run_name',
        flex: 1,
        renderHeader: () => <strong>ngs_run_name</strong>,
      },
      {
        field: 'analysis_name',
        flex: 1,
        renderHeader: () => <strong>analysis_name</strong>,
      },
      {
        field: 'plate_name',
        flex: 2,
        renderHeader: () => <strong>plate_name</strong>,
      },
      {
        field: 'well',
        flex: 0.5,
        renderHeader: () => <strong>well</strong>,
      },
      {
        field: 'library_number',
        flex: 1,
        renderHeader: () => <strong>library_number</strong>,
      },
      {
        field: 'library_well',
        flex: 0.5,
        renderHeader: () => <strong>library_well</strong>,
      },
      {
        field: 'library_prep_plate',
        flex: 1,
        renderHeader: () => <strong>library_prep_plate</strong>,
      },
      {
        field: 'library_prep_kit',
        flex: 1,
        renderHeader: () => <strong>library_prep_kit</strong>,
      },
      {
        field: fieldToChange,
        flex: 1,
        editable: true,
        renderHeader: () => <strong>{fieldToChange}</strong>,
      },
    ],
    [fieldToChange]
  );

  useEffect(() => {
    if (uploadedFile)
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          for (let i = 0; i < results.data.length; i++) {
            results.data[i].id = i;
          }
          let formattedData = formatInputData(results.data);
          setRows(formattedData);
        },
      });
  }, [uploadedFile]);

  useEffect(() => {
    if (response) setOpenSnackbar(true);
  }, [response]);

  // Handlers
  const handleFileUpload = (file) => {
    setUploadedFile(file);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleEditRows = () => {
    setOpen(true);
  };

  const handleChangeValues = () => {
    // Update the rows with the new value
    // From the rowSelectionModel, change the value of the fieldToChange
    const newRows = [...rows];
    rowSelectionModel.forEach((rowId) => {
      const row = newRows.find((row) => row.id === rowId);
      row[fieldToChange] = valueToChange;
    });
    setRows(newRows);
    setOpen(false);
    setHasValueChanged(true);
  };

  const handleSubmit = () => {
    console.debug('DB:', `${process.env.REACT_APP_DB_NAME}`);
    setOpenConfirmSubmit(true);
  };

  const handleConfirmSubmit = () => {
    const fileKey = `datahub/${uploadedFile.path}`;
    setLoading(true);
    setOpenLoader(true);
    const newRows = addMetadata(rows, account.name, method);
    uploadCsvDataToS3(newRows, 'ds-data-consolidation', fileKey).then((res) => {
      console.debug(res);
      if (res.upload_status === 'ok') {
        console.debug('upload successfull');
        let event =
          fieldToChange === 'consolidation_status' ||
          fieldToChange === 'consolidation_name'
            ? 'ngs_db_changes_run'
            : 'update_ngs_well_run';
        triggerLambda('data_consolidation_lambda', {
          eventType: event,
          body: {
            bucketName: 'ds-data-consolidation',
            fileKey: res.file_key,
            field: fieldToChange,
            method: method,
            database: `${process.env.REACT_APP_DB_NAME}`,
          },
        }).then((r) => {
          setLoading(false);
          if (r.statusCode === 200) {
            console.debug('lambda successfull');
            setResponse({
              status: 'ok',
              message: 'Data successfully submited',
            });
          } else
            setResponse({
              status: 'error',
              message: 'Error during data validation',
            });
        });
      } else {
        setLoading(false);
        setResponse({
          status: 'error',
          message: 'Error submitting file',
        });
      }
    });
    setOpenConfirmSubmit(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Smaller components
  const valueDialog = (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle id='alert-dialog-title'>
        Set the value for <strong>{fieldToChange}</strong>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {fieldToChange !== 'consolidation_status' ? (
          <>
            <TextField
              autoFocus
              id='runId'
              fullWidth
              variant='standard'
              placeholder={
                fieldToChange === 'consolidation_name'
                  ? consolidation_name_placeholder
                  : condition_placeholder
              }
              onChange={(event) => {
                setValueToChange(event.target.value);
              }}
            />
            {fieldToChange === 'consolidation_name' && (
              <Typography variant='caption' display='block'>
                To add more than one value, separate them with a semi-colon (;)
              </Typography>
            )}
          </>
        ) : (
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='radio-buttons-group-label'
                name='radio-buttons-group'
                onChange={(event) => setValueToChange(event.target.value)}>
                <FormControlLabel
                  value='True'
                  control={<Radio />}
                  label='True'
                />
                <FormControlLabel
                  value='False'
                  control={<Radio />}
                  label='False'
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
        <Button onClick={handleChangeValues}>Done</Button>
      </DialogActions>
    </Dialog>
  );

  const confirmSubmitDialog = (
    <Dialog open={openConfirmSubmit} fullWidth maxWidth='xs'>
      <DialogTitle id='alert-dialog-title'>Confirm submit</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to submit your changes to the database?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            setOpenConfirmSubmit(false);
          }}>
          Cancel
        </Button>
        <Button onClick={handleConfirmSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );

  const snackbar = (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}>
      <Alert
        onClose={handleCloseSnackbar}
        severity={response && response.status === 'ok' ? 'success' : 'error'}
        sx={{ width: '100%' }}>
        {response && response.message}
      </Alert>
    </Snackbar>
  );

  const loader = (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openLoader}>
      <CircularProgress />
    </Backdrop>
  );

  const step1 = (
    <Box m={5} display='flex' flexDirection='column' alignItems='center'>
      <Paper elevation={2} sx={{ padding: '1rem' }}>
        <Box>
          <FormControl>
            <FormLabel id='field-to-change-label'>Field to change</FormLabel>
            <RadioGroup
              row
              aria-labelledby='radio-buttons-group-label'
              name='radio-buttons-group'
              onChange={(event) => setFieldToChange(event.target.value)}>
              {fields.map((field) => (
                <FormControlLabel
                  key={field}
                  value={field}
                  control={<Radio />}
                  label={field}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          pt: 2,
        }}>
        <Button
          onClick={handleNext}
          disabled={!fieldToChange}
          variant='contained'>
          Next
        </Button>
      </Box>
    </Box>
  );

  const step2 = (
    <Box m={5} display='flex' flexDirection='column' alignItems='center'>
      <Paper elevation={2} sx={{ padding: '1rem', marginBottom: '1rem' }}>
        <Alert severity='info' variant='outlined' sx={{ boxShadow: 0 }}>
          <Typography variant='caption'>
            The file must be a <strong>CSV</strong> and should contain the
            following columns: ngs_run_name, analysis_name, plate_name, well,
            library_number, library_well, library_prep_plate and
            library_prep_kit
          </Typography>
        </Alert>
        <Box mt={1} mb={1}>
          <Dropzone
            acceptedExtentions={{ 'text/csv': ['.csv'] }}
            messageToDisplay={'Drag a file or click to upload a CSV file'}
            maxFiles={1}
            disabled={false}
            setFile={handleFileUpload}
          />
        </Box>
        {uploadedFile && (
          <Alert severity='success' variant='outlined' sx={{ boxShadow: 0 }}>
            File uploaded: {uploadedFile.path}
          </Alert>
        )}
      </Paper>

      <Button onClick={handleNext} disabled={!uploadedFile} variant='contained'>
        Next
      </Button>
    </Box>
  );

  const step3 = (
    <>
      {valueDialog}
      {confirmSubmitDialog}
      {snackbar}
      {loading && loader}
      <Box display='flex' justifyContent='flex-end'>
        <Button
          color='secondary'
          size='small'
          variant='contained'
          onClick={handleEditRows}
          disabled={rowSelectionModel.length < 1}>
          Edit selected rows
        </Button>
      </Box>
      {fieldToChange && fieldToChange !== 'consolidation_status' && (
        <Box display='flex' justifyContent='flex-end'>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby='radio-buttons-group-label'
              name='radio-buttons-group'
              onChange={(event) => setMethod(event.target.value)}>
              <FormControlLabel value='add' control={<Radio />} label='Add' />
              <FormControlLabel
                value='replace'
                control={<Radio />}
                label='Replace'
              />
              <FormControlLabel
                value='remove'
                control={<Radio />}
                label='Delete'
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      <Box sx={{ height: 600, width: '100%' }} mt={1}>
        <DataGridPremium
          slots={{ toolbar: CustomGridToolbar }}
          density='compact'
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          experimentalFeatures={{ clipboardPaste: true }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </>
  );

  // Main return
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title={`NGS DB changes`} stepper>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 2 && (
            <Box>
              <Button
                color='primary'
                variant='contained'
                onClick={handleSubmit}
                disabled={!hasValueChanged}>
                Submit
              </Button>
            </Box>
          )}
        </PageTitle>
        {activeStep === 0 && step1}
        {activeStep === 1 && step2}
        {activeStep === 2 && uploadedFile && fieldToChange && step3}
      </AuthenticatedTemplate>
    </>
  );
};

export default NgsDbChanges;
