import React, { useEffect, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { HOME_PAGE_ITEMS } from '../../data/homePageItems';
import HomepageCard from '../common/HomepageCard';
import UnauthenticatedPage from '../common/UnauthenticatedPage.jsx';

const Homepage = ({ userGroups }) => {
  const [itemsToShow, setItemsToShow] = useState([]);

  // Check user groups and set items to show
  useEffect(() => {
    let filteredItems = [
      HOME_PAGE_ITEMS.SEQUENCING_ANALYSIS,
      HOME_PAGE_ITEMS.TOOLS,
      HOME_PAGE_ITEMS.NGS_REPORT,
      HOME_PAGE_ITEMS.SYNTHESIS_REPORT,
    ];
    if (userGroups?.includes(`${process.env.REACT_APP_NGS_DB_CHANGES_GROUP}`))
      filteredItems.push(HOME_PAGE_ITEMS.NGS_DB_CHANGES);
    if (
      userGroups?.includes(
        `${process.env.REACT_APP_SYNTHESIS_DB_CHANGES_GROUP}`
      )
    )
      filteredItems.push(HOME_PAGE_ITEMS.SYNTHESIS_DB_CHANGES);
    setItemsToShow(filteredItems);
  }, [userGroups]);

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Container>
          <Grid container spacing={2}>
            {Object.keys(itemsToShow).map((item) => {
              return (
                <HomepageCard
                  xs={6}
                  url={itemsToShow[item].url}
                  icon={itemsToShow[item].icon}
                  linkName={itemsToShow[item].title}
                  key={itemsToShow[item].url}
                />
              );
            })}
          </Grid>
        </Container>
      </AuthenticatedTemplate>
    </>
  );
};

export default Homepage;
