export async function getAuthorizedPlateTags() {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/plate-tags`);
  const responseJson = await response.json();
  return responseJson;
}

export async function getPlateNames() {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/plateNames`);
  const responseJson = await response.json();
  return responseJson;
}
