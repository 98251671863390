import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPathName } from '../../utils/formatData';

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      <Link onClick={() => navigate('/')} underline='none'>
        <HomeIcon fontSize='small' />
      </Link>
      {pathnames.map((name, index) => {
        const pushTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = !(index === pathnames.length - 1);
        return isLast ? (
          <Link onClick={() => navigate(pushTo)} key={index} underline='none'>
            <Typography key={index} variant='overline'>
              {formatPathName(name)}
            </Typography>
          </Link>
        ) : (
          <Typography key={index} variant='overline'>
            {formatPathName(name)}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
