import React, { useCallback, useMemo, useState } from 'react';
import './uploadGeneAssemblySs.scss';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import {
  deleteSampleSheet,
  uploadSampleSheetToFinalBucket,
  uploadSampleSheetToTempBucket,
  validateSampleSheet,
} from '../../services/SampleSheetsService';
import Breadcrumbs from '../common/Breadcrumbs';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import PageTitle from '../layout/PageTitle';

const UploadGeneAssemblySS = () => {
  // State and initial definitions
  const breakpoint = useMediaQuery('(min-width:1100px)');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [warnings, setWarnings] = useState(null);
  const [errors, setErrors] = useState(null);
  const [lambdaErrors, setLambdaErrors] = useState(null);
  const [uploadErrors, setUploadErrors] = useState(null);
  const [validationResults, setValidationResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [openSubmitWithWarnings, setOpenSubmitWithWarnings] = useState(false);
  const sampleSheetUserGuideUrl =
    'https://dnascript.atlassian.net/wiki/spaces/DS/pages/2463203339/MiSeq+Sample+Sheet';
  const downloadSampleSheetTemplateUrl =
    'https://dnascriptco.sharepoint.com/sites/data_science/Bibliothque/Forms/AllItems.aspx?id=%2Fsites%2Fdata%5Fscience%2FBibliothque%2FDocuments%2F04%2E%20Apps%2BBioinfo%2Ftemplates%2Fsample%5Fsheet%5Ftemplate%2Etsv&parent=%2Fsites%2Fdata%5Fscience%2FBibliothque%2FDocuments%2F04%2E%20Apps%2BBioinfo%2Ftemplates&p=true&ga=1';

  const onDrop = useCallback((acceptedFiles) => {
    setFileToUpload(acceptedFiles[0]);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.tsv'],
    },
    maxFiles: 1,
    onDrop,
    disabled: fileToUpload ? true : false, // disable the dropzone if there is already a selected file
  });

  const className = useMemo(() => {
    return isFocused
      ? 'dropzone focused'
      : isDragAccept
      ? 'dropzone accept'
      : isDragReject
      ? 'dropzone reject'
      : 'dropzone';
  }, [isFocused, isDragAccept, isDragReject]);

  // Event handlers
  const handleReset = () => {
    setFileToUpload(null);
    setWarnings(null);
    setErrors(null);
    setValidationResults(null);
    setUploadErrors(null);
  };

  const handleSubmitForValidation = () => {
    setLoading(true);
    setOpen(true);
    // 1. Upload sample sheet to temporary S3 bucket
    uploadSampleSheetToTempBucket(acceptedFiles[0]).then((response) => {
      // If there's an error uploading, set the error
      if (response.upload_error) {
        setUploadErrors(response.upload_error);
        setLoading(false);
        setOpen(false);
        // If there file was uploaded correctly, validate sample sheet
      } else {
        validateSampleSheet(response.file_key).then((resp) => {
          if (resp.warnings && resp.warnings.length > 0) {
            setWarnings(resp.warnings);
          }
          if (resp.errors && resp.errors.length > 0) {
            setErrors(resp.errors);
          }
          if (resp.lambda_error && resp.lambda_error.length > 0) {
            setLambdaErrors(resp.lambda_error);
          }
          // If no error was found, upload to the final destination
          else {
            uploadSampleSheetToFinalBucket(acceptedFiles[0]).then((r) => {
              if (r.upload_error) {
                setErrors([r.upload_error]);
              } else {
                deleteSampleSheet(response.file_key);
              }
              setLoading(false);
              setOpen(false);
            });
          }
          setValidationResults(resp);
          setLoading(false);
          setOpen(false);
        });
      }
    });
  };

  const handleOpenSubmitWithWarnings = () => {
    setOpenSubmitWithWarnings(true);
  };
  const handleCloseSubmitWithWarnings = () => {
    setOpenSubmitWithWarnings(false);
  };

  const confirmSubmitWithWarnings = () => {
    setLoading(true);
    setOpen(true);
    uploadSampleSheetToFinalBucket(acceptedFiles[0]).then((response) => {
      if (response.upload_error) {
        setUploadErrors(response.upload_error);
        setLoading(false);
        setOpen(false);
        // If there file was uploaded correctly, validate sample sheet
      }
      setLoading(false);
      setOpen(false);
      setWarnings(null);
      setOpenSubmitWithWarnings(false);
    });
  };

  const submitWithWarningsDialog = (
    <Dialog
      open={openSubmitWithWarnings}
      onClose={handleCloseSubmitWithWarnings}>
      <DialogTitle id='alert-dialog-title'>{'Warning'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to run the pipeline with warnings?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSubmitWithWarnings}>No</Button>
        <Button onClick={confirmSubmitWithWarnings} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Components
  const files = acceptedFiles.length > 0 && (
    <Typography key={acceptedFiles[0].path}>
      <strong>File:</strong> {acceptedFiles[0].path}
    </Typography>
  );

  const showSuccess = validationResults &&
    !warnings &&
    !errors &&
    !lambdaErrors &&
    !uploadErrors && (
      <Alert severity='success'>
        The file was successfully validated. The pipeline was launched.
      </Alert>
    );

  const showWarnings = (
    <Box mb={2}>
      {warnings && (
        <Alert severity='warning'>
          <ul>
            {warnings &&
              warnings.map((warning, i) => (
                <li key={i}>
                  <Typography>{warning}</Typography>
                </li>
              ))}
          </ul>
        </Alert>
      )}
    </Box>
  );

  const showErrors = (
    <Box mb={2}>
      {errors && (
        <Alert severity='error'>
          <ul>
            {errors &&
              errors.map((error, i) => (
                <li key={i}>
                  <Typography>{error}</Typography>
                </li>
              ))}
          </ul>
        </Alert>
      )}
    </Box>
  );

  const showLambdaErrors = lambdaErrors && (
    <Alert severity='error'>{lambdaErrors}</Alert>
  );

  const showUploadErrors = uploadErrors && (
    <Alert severity='error'>{uploadErrors}</Alert>
  );

  const dropzone = (
    <Box className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography>
        Drop a .tsv or .txt file here or click to select one
      </Typography>
    </Box>
  );

  const loader = (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}>
      <CircularProgress />
    </Backdrop>
  );

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Box mb={2}>
          <Breadcrumbs />
        </Box>
        <PageTitle title='Upload Gene Assembly Sample Sheet' />
        {loading && loader}
        {submitWithWarningsDialog}
        <Grid container spacing={3} direction={breakpoint ? 'row' : 'column'}>
          <Grid item xs={6}>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='h4'>Submit file for validation</Typography>
              <Tooltip title='Sample sheets user guide'>
                <IconButton href={sampleSheetUserGuideUrl} target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Paper elevation={1} className='paper'>
              <Box display='flex' justifyContent='flex-end' mb={1}>
                <Link href={downloadSampleSheetTemplateUrl} target='_blank'>
                  Download template
                </Link>
              </Box>
              {dropzone}
              <Box display='flex' flexDirection='column' flexWrap='wrap' mt={2}>
                {fileToUpload && files}
                <Box flexGrow={1}></Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  flexWrap='wrap'
                  gap={1}
                  justifyContent='center'
                  mt={2}>
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={handleReset}
                    disabled={fileToUpload ? false : true}>
                    clear
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleSubmitForValidation}
                    size='small'
                    disabled={
                      validationResults ? true : fileToUpload ? false : true
                    }>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h4'>Validation results</Typography>
            <Paper elevation={1} className='paper'>
              {errors && (
                <Box mb={1}>
                  <Typography>
                    Errors were found during validation. Please clear the file,
                    fix the errors and re-submit the file.
                  </Typography>
                </Box>
              )}
              {showWarnings}
              {validationResults && warnings && !errors && (
                <>
                  <Box display='flex' flexDirection='row' mt={3}>
                    <Box flexGrow={1}></Box>
                    <Button
                      variant='contained'
                      onClick={handleOpenSubmitWithWarnings}
                      size='small'>
                      Upload with warnings
                    </Button>
                  </Box>
                </>
              )}
              {showUploadErrors}
              {showLambdaErrors}
              {showErrors}
              {showSuccess}
              {validationResults && (warnings || errors) && (
                <Link href={sampleSheetUserGuideUrl} target='_blank'>
                  Check user guide for instructions on how to fix the errors
                </Link>
              )}
            </Paper>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
};

export default UploadGeneAssemblySS;
