import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import UnauthenticatedPage from '../common/UnauthenticatedPage';
import PageTitle from '../layout/PageTitle';

const Gel = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageTitle title='Gels' />
      </AuthenticatedTemplate>
    </>
  );
};

export default Gel;
